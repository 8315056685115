<div class="container-fluid">
  <div class="display-box row">

    <div id="prev-buttons" class="col-1">
      <div id="prev-recipe" class="recipe-row d-flex pagination-button page-switcher">
        <div
          [dndDropzone]="[]"
          (dragenter)="onPreviousPageDragStart()"
          (dragleave)="onSwitchPageDragLeave()"
          dndDragoverClass="active"
          class="page-switch-drag-zone left"
          [class.display]="isDraggingRecipe"
        ></div>
        <img
          role="button"
          src="../../../assets/images/icon_prev.png"
          (click)="prevPage(dataSourceRecipes)"
        />
      </div>
      <div id="prev-multilevel" class="folders-row d-flex pagination-button">
        <img
          role="button"
          src="../../../assets/images/icon_prev.png"
          (click)="prevPage(dataSourceMultilevelFolders)"
        />
      </div>
      <div id="prev-folder" class="folders-row d-flex pagination-button">
        <img
          role="button"
          src="../../../assets/images/icon_prev.png"
          (click)="prevPage(dataSourceFolders)"
        />
      </div>
    </div>

    <div id="content" class="display-content display-border col-10">
      <div id="recipe-box" class="recipe-row recipes-box">
        <div
        class="recipes-box"
        [dndDropzone]="[]"
        (dndDrop)="onDropDisplay($event)"
        dndDragoverClass="custom-drag-over"
      >
        <div class="dndPlaceholder" dndPlaceholderRef #placeholder></div>
        <ng-container *ngFor="let r of recipes, let index = index">
          <app-recipe-box
            [hidden]="index < dataSourceRecipes.page * getRecipePerPage() || index > dataSourceRecipes.page * getRecipePerPage() + (getRecipePerPage()-1)"
            [recipe]="r!"
            (onDragRecipeStart)="onDragRecipesStart($event)"
            (onDragRecipeEnd)="onDragEnd($event)"
            (onDragRecipe)="onDragging($event)"
            [draggable]="true"
            [customClass]="'on-display'"
            [id]="'recipe_box_' + r.id"
          ></app-recipe-box>
        </ng-container>
        </div>
        <div class="pagination-counter">
          <div
            *ngFor="
              let item of [].constructor(totalPagesRecipes);
              let i = index
            "
            class="counter-pages"
            [ngClass]="{ active: dataSourceRecipes.page === i }"
            [id]="i"
          ></div>
        </div>
      </div>
      <div id="multilevel-box" class="folders-row folder-box">
        <div class="folders-box">
          <app-recipe-folder
            *ngFor="let f of dataSourceMultilevelFolders.currentItems"
            [deviceModel]="deviceModel"
            [folder]="f"
            [dndDropzone]="[]"
            (dndDrop)="onDropFolder($event, f)"
            dndDragoverClass="custom-drag-over"
            (onFolderDeleted)="deleteFolder($event)"
          ></app-recipe-folder>
          <div
            *ngIf="
              !dataSourceMultilevelFolders.currentItems ||
              dataSourceMultilevelFolders.currentItems.length === 0
            "
            class="placeholder-folder"
          ></div>
        </div>
        <div class="pagination-counter">
          <div
            *ngFor="
              let item of [].constructor(totalPagesMultilevelFolder);
              let i = index
            "
            class="counter-pages"
            [ngClass]="{ active: dataSourceMultilevelFolders.page === i }"
            [id]="i"
          ></div>
        </div>
      </div>
      <div id="folder-box" class="folders-row folder-box">
        <div class="folders-box">
          <app-recipe-folder
            *ngFor="let f of dataSourceFolders.currentItems"
            [deviceModel]="deviceModel"
            [folder]="f"
            [dndDropzone]="[]"
            (dndDrop)="onDropFolder($event, f)"
            dndDragoverClass="custom-drag-over"
            (onFolderDeleted)="deleteFolder($event)"
          ></app-recipe-folder>
          <div
            *ngIf="
              !dataSourceFolders.currentItems ||
              dataSourceFolders.currentItems.length === 0
            "
            class="placeholder-folder"
          ></div>
        </div>
        <div class="pagination-counter">
          <div
            *ngFor="
              let item of [].constructor(totalPagesFolder);
              let i = index
            "
            class="counter-pages"
            [ngClass]="{ active: dataSourceFolders.page === i }"
            [id]="i"
          ></div>
        </div>
      </div>
      <div id="buttons-box" class="display-buttons">
        <div class="row">
          <div class="col-3 border-end border-bottom">
            <img src="../../../assets/images/display_icon_manuale.png" />
          </div>
          <div class="col-3 border-end border-bottom">
            <img src="../../../assets/images/nabook/menu_icona_ml.png" />
          </div>
          <div class="col-3 border-end border-bottom">
            <img src="../../../assets/images/display_icon_new.png" />
          </div>
          <div class="col-3 border-end border-bottom">
            <img src="../../../assets/images/nabook/menu_icona_lavaggio.png" />
          </div>
          <div class="col-3 border-bottom">
            <img src="../../../assets/images/display_icon_settings.png" />
          </div>
        </div>
        <div class="row">
          <div class="col-3 border-end ">
            <img class="w-75" src="../../../assets/images/display_icon_mynabook.png" />
          </div>
          <div class="col-7 border-end">
            <img src="../../../assets/images/display_icon_cloud.png" />
          </div>
          <div class="col-3">
            <img src="../../../assets/images/display_icon_help.png" />
          </div>
        </div>
      </div>
    </div>

    <div id="next-buttons" class="col-1">
      <div id="next-recipe" class="recipe-row d-flex pagination-button page-switcher">
        <div
          [dndDropzone]="[]"
          (dragenter)="onNextPageDragStart()"
          (dragleave)="onSwitchPageDragLeave()"
          dndDragoverClass="active"
          class="page-switch-drag-zone"
          [class.display]="isDraggingRecipe"
        ></div>
        <img
          role="button"
          src="../../../assets/images/icon_next.png"
          (click)="nextPage(dataSourceRecipes)"
        />
      </div>
      <div id="next-multilevel" class="folders-row d-flex pagination-button">
        <img
          role="button"
          src="../../../assets/images/icon_next.png"
          (click)="nextPage(dataSourceMultilevelFolders)"
        />
      </div>
      <div id="next-folder" class="folders-row d-flex pagination-button">
        <img
          role="button"
          src="../../../assets/images/icon_next.png"
          (click)="nextPage(dataSourceFolders)"
        />
      </div>
    </div>

  </div>
</div>
