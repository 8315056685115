import { Injectable } from '@angular/core';
import {
  contains,
  equal,
  ODataFiltrableDataSource
} from 'filtrable-data-source';
import { DisplayBoDtoNBK } from '../../api/nbk';

interface filterAction {
  [key: string]: () => void;
}

@Injectable({
  providedIn: 'root'
})
export class MyDisplayFiltersService {
  filterFormValue: any;

  constructor() { }

  setFilterForm(form: any) {
    this.filterFormValue = form;
  }

  setCustomFilter(
    displayList: ODataFiltrableDataSource<DisplayBoDtoNBK>
  ) {
    const setFilter: filterAction = {
      name: () => {
        displayList.setFilter(
          'name', 
          contains(this.filterFormValue.name));
      },
      device: () => {
        displayList.setCustomFilter(
          `contains(devices/name, '${this.filterFormValue.device}') or contains(devices/serial, '${this.filterFormValue.device}')`
        );
      },
      recipe: () => {
        displayList.setCustomFilter(
          `contains(recipes/name/value, '${this.filterFormValue.recipe}')` 
          //TODO non funziona se non ha cartelle perchè la join la fa in inner
          // or contains(folders/recipes/name/value, '${this.filterFormValue.recipe}')`
        );
      },
      deviceModel: () => {
        displayList.setFilter(
          'deviceModel', 
          equal(this.filterFormValue.deviceModel));
      },
      cookingMode: () => {
        displayList.setFilter(
          'cookingMode', 
          equal(this.filterFormValue.cookingMode));
      },
      excludeDefualt: () => {
        displayList.setFilter(
          'constructor', 
          equal(false));
      }
    };

    this.resetFilter(displayList);

    for (const field in this.filterFormValue) {
      if (this.filterFormValue[field]) {
        setFilter[field]();
      }
    }
  }

  resetFilter(
    displayList: ODataFiltrableDataSource<DisplayBoDtoNBK>
  ) {
    displayList.removeFilter('name');
    displayList.removeFilter('deviceModel');
    displayList.removeFilter('cookingMode');
    displayList.removeFilter('constructor');
    displayList.removeCustomFilter();
  }

}
