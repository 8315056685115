import { Output, EventEmitter, Component } from '@angular/core';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { FolderBoDtoNBK, RecipeBoDtoNBK } from 'src/app/api/nbk';
import { MyDisplayService } from '../my-display.service';

export interface RecipeDrop {
  recipe: RecipeBoDtoNBK;
  newIndex: number;
  currentPage: number;
  recipePerPage: number;
}

@Component({template: ``})
export abstract class DisplayHandler {
  
  switchPageTimeout: number;
  isDraggingRecipe: boolean = false;

  @Output() onDragEndRecipesDisplay: EventEmitter<RecipeDrop> = new EventEmitter();

  @Output() onDragEndRecipesFolder: EventEmitter<{
    recipe: RecipeBoDtoNBK;
    folder: any;
  }> = new EventEmitter();

  @Output() onFolderDeleted: EventEmitter<FolderBoDtoNBK> = new EventEmitter();

  constructor(
    public myDisplayService: MyDisplayService
  ) {  }

  protected nextPage(
    dataSource:
      | ClientFiltrableDataSource<RecipeBoDtoNBK>
      | ClientFiltrableDataSource<FolderBoDtoNBK>
  ) {
    const { page, limit, totalItems } = dataSource;
    if (totalItems && totalItems > (page + 1) * limit) {
      dataSource.setPage(page + 1);
    } else {
      dataSource.setPage(0);
    }
  }

  protected prevPage(
    dataSource:
      | ClientFiltrableDataSource<RecipeBoDtoNBK>
      | ClientFiltrableDataSource<FolderBoDtoNBK>
  ) {
    const { page } = dataSource;
    if (page != 0) {
      dataSource.setPage(page - 1);
    } else {
      const totalPages = this.getTotalPages(dataSource);
      dataSource.setPage(totalPages - 1);
    }
  }

  protected onDropDisplay(event: any) {
    this.myDisplayService.recipeDragged = event.data;
    const recipe: RecipeDrop = {
      recipe: event.data,
      newIndex: event.index - this.getRecipeDatasource().page * this.getRecipePerPage(),
      currentPage: this.getRecipeDatasource().page,
      recipePerPage: this.getRecipePerPage()
    };
    this.onDragEndRecipesDisplay.emit(recipe);
  }

  protected onPreviousPageDragStart(): void {
    let timeout = 200;
    if (this.switchPageTimeout) {
      clearTimeout(this.switchPageTimeout);
      this.switchPageTimeout = 0;
      timeout = 2000;
    }
    this.switchPageTimeout = setTimeout(() => {
      this.prevPage(this.getRecipeDatasource());
      this.onPreviousPageDragStart();
    }, timeout);
  }

  protected onNextPageDragStart(): void {
    let timeout = 200;
    if (this.switchPageTimeout) {
      clearTimeout(this.switchPageTimeout);
      this.switchPageTimeout = 0;
      timeout = 1500;
    }
    this.switchPageTimeout = setTimeout(() => {
      this.nextPage(this.getRecipeDatasource());
      this.onNextPageDragStart();
    }, timeout);
  }

  protected onSwitchPageDragLeave(): void {
    if (this.switchPageTimeout) {
      clearTimeout(this.switchPageTimeout);
      this.switchPageTimeout = 0;
    }
  }

  protected onDropFolder(event: any, folder: any) {
    this.myDisplayService.recipeDragged = event.data;
    this.onDragEndRecipesFolder.emit({ recipe: event.data, folder: folder });
  }

  protected onDragRecipesStart(event: any) {
    this.myDisplayService.isAlreadyInDisplay = true;
    this.myDisplayService.recipeOnDragging = event.target.id;
    this.isDraggingRecipe = true;
  }

  protected onDragging(id: number) {
    const element = document.querySelector('#recipe_box_' + id);
    element?.classList.add('hide-box');
  }

  protected onDragEnd(id: number) {
    this.myDisplayService.isAlreadyInDisplay = false;
    const element = document.querySelector('#recipe_box_' + id);
    element?.classList.remove('hide-box');
    this.isDraggingRecipe = false;
    clearTimeout(this.switchPageTimeout);
    this.switchPageTimeout = 0;
  }

  protected getTotalPages(
    dataSource:
      | ClientFiltrableDataSource<RecipeBoDtoNBK>
      | ClientFiltrableDataSource<FolderBoDtoNBK>
  ) {
    const { limit, totalItems } = dataSource;
    return Math.floor((totalItems! + limit - 1) / limit);
  }

  protected deleteFolder(folder: FolderBoDtoNBK) {
    this.onFolderDeleted.emit(folder);
  }

  abstract getRecipePerPage(): number;
  abstract getRecipeDatasource(): ClientFiltrableDataSource<RecipeBoDtoNBK>;
  abstract getFolderDatasource(): ClientFiltrableDataSource<FolderBoDtoNBK>;
  abstract getMultilevelFolderDatasource(): ClientFiltrableDataSource<FolderBoDtoNBK> | undefined;

}
