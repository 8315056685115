import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { FolderBoDtoNBK, RecipeBoDtoNBK } from 'src/app/api/nbk';
import { CookingModeEnum, DeviceModelEnum, getCookingModeList, SelectableCookingMode } from 'src/app/core/utils';
import { MyDisplayService } from '../../my-display.service';
import { DisplayHandler } from '../display.handler';

@Component({
  selector: 'app-display-oracle',
  templateUrl: './display-oracle.component.html',
  styleUrls: ['./display-oracle.component.scss']
})
export class DisplayOracleComponent extends DisplayHandler implements OnInit {
  dataSourceRecipes: ClientFiltrableDataSource<RecipeBoDtoNBK> =
    new ClientFiltrableDataSource();
  dataSourceFolders: ClientFiltrableDataSource<FolderBoDtoNBK> =
    new ClientFiltrableDataSource();

  totalPagesRecipes: number;
  totalPagesFolder: number;
  cookingModeList: SelectableCookingMode[];
  isEmpty: boolean = false;


  @Input() recipes: RecipeBoDtoNBK[] = [];
  @Input() folders: FolderBoDtoNBK[] = [];
  @Input() deviceModel: DeviceModelEnum;
  @Input() cookingMode: CookingModeEnum = 'COMBI';


  constructor(
    public myDisplayService: MyDisplayService,
    private ts: TranslateService
  ) {
    super(myDisplayService);

    //TODO to review when enabled other device management
    this.cookingModeList = getCookingModeList('ORACLE');
  }

  ngOnInit(): void {
    this.dataSourceRecipes.limit = this.getRecipePerPage();
    this.dataSourceFolders.limit = 5;

    this.dataSourceRecipes.setItems(this.recipes);
    this.dataSourceRecipes.setPage(0);

    this.dataSourceFolders.setItems(this.folders);
    this.dataSourceFolders.setPage(0);

    this.totalPagesRecipes = this.getTotalPages(this.dataSourceRecipes);
    this.totalPagesFolder = this.getTotalPages(this.dataSourceFolders);

    this.isEmpty = this.recipes.length === 0 && this.folders.length === 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.recipes) {
      this.dataSourceRecipes.setItems(this.recipes);
      if (
        this.myDisplayService.goToLastPage ||
        this.dataSourceRecipes.items?.length! % this.dataSourceRecipes.limit ===
          0 ||
        this.myDisplayService.resetDisplayRecipe == true
      ) {
        this.myDisplayService.goToLastPage = false;
        this.totalPagesRecipes = this.getTotalPages(this.dataSourceRecipes);
        const newPage =
          this.totalPagesRecipes - 1 < 0 ? 0 : this.totalPagesRecipes - 1;
        this.dataSourceRecipes.setPage(newPage);
      }

      if (this.myDisplayService.resetDisplayRecipe == true) {
        this.totalPagesRecipes = this.getTotalPages(this.dataSourceRecipes);
        this.dataSourceRecipes.setPage(0);
        this.myDisplayService.resetDisplayRecipe = false;
      }
    }

    if (changes.folders) {
      this.dataSourceFolders.setItems(this.folders);
      this.totalPagesFolder = this.getTotalPages(this.dataSourceFolders);
      this.dataSourceFolders.setPage(this.totalPagesFolder - 1);

      if (this.myDisplayService.resetDisplayFolder == true) {
        this.totalPagesFolder = this.getTotalPages(this.dataSourceFolders);
        this.dataSourceFolders.setPage(0);
        this.myDisplayService.resetDisplayFolder = false;
      }
    }

    this.isEmpty = this.recipes.length === 0 && this.folders.length === 0;
  }


  getRecipeDatasource() {
    return this.dataSourceRecipes;
  }

  getFolderDatasource() {
    return this.dataSourceFolders;
  }

  getMultilevelFolderDatasource() {
    return undefined;
  }

  getRecipePerPage(): number {
    return 10;
  }

}
